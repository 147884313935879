import React from "react"

import Header from "../components/header"
import Footer from "../components/footer"

import SEO from "../components/seo"

import Werksprozess1 from "../components/werksprozess1"
import Werksprozess2 from "../components/werksprozess2"
import Werksprozess3 from "../components/werksprozess3"
import Werksprozess4 from "../components/werksprozess4"

import "./index.css"

export default function WerksprozessPage() {
  return (
    <>
      <SEO
        description="restaura ratisbona, Ihr Team für Restaurierungen. Werksprozess"
        title="restaura ratisbona, Ihr Team für Restaurierungen. Werksprozess"
        lang="de"
      />
      <Header />
      <section>
        <h1 className="indexHeadline">Werksprozess</h1>
        <h2 className="referenceH2">
          Wallfahrtskirche „Zur Freudenreichen Dreifaltigkeit“ Gößweinstein
        </h2>
        <h3 className="referenceH3">
          Restaurierung und Erfassung der Werkprozesse an den Deckengemälden
        </h3>
        <div className="referencePageDiv">
          <div className="referenceImages">
            <label htmlFor="Werksprozess1">
              <Werksprozess1 id="Werksprozess1" />
              Kirchenschiff, Gemälde mit der Darstellung der 14 Nothelfer.
              Kartierung der Tagewerksgrenzen: Die schematische Zeichnung lässt
              erkennen, dass die sich die Grenzlinien der Giornata in der
              unteren Bildhälfte formal an den Umrissen der Figuren orientierte
              <br />
              (Foto: Astrid Mendes)
            </label>
            <label htmlFor="Werksprozess2">
              <Werksprozess2 id="Werksprozess2" />
              Gößweinstein, Kuppelbild, Kartierung der Tagewerksgrenzen
              mit Konstruktionslinien. Deutlich ist zu erkennen, dass Waldemar
              Kolmsperger mit der „Giornata“ im Zentrum der Darstellung, nämlich
              mit der Weltkugel, begonnen hat und kreisförmig die weiteren
              Tagewerke anlegte.
              <br />
              (Foto: Astrid Mendes)
            </label>
            <label htmlFor="Werksprozess3">
              <Werksprozess3 id="Werksprozess3" />
              Die freskale Maltechnik wurde durch eine naturwissenschaftliche
              Untersuchung nachgewiesen
              <br />
              (Autorin Astrid Mendes)
            </label>
            <label htmlFor="Werksprozess4">
              <Werksprozess4 id="Werksprozess4" />
              Chorgemälde Jüngstes Gericht, sichtbare Ritzungen im Streiflicht
              <br />
              (Autorin Astrid Mendes)
            </label>
          </div>
          <div className="referenceTextDiv">
            <h4 className="referenceH4">Geschichte und Bedeutung</h4>
            <p className="referenceText">
              Die Kirche wurde 1730–39 von Balthasar Neumann und Johann Jakob
              Michael Küchel erbaut. Die Fresken schuf 1928 im Stil des Barocks
              Professor Waldemar Kolmsperger mit seinem gleichnamigen Sohn. Als
              akademisch vorgebildeter Maler hat Kolmsperger, ähnlich wie
              Tiepolo, seinen Freskenzyklus mit Hilfe von Skizzen detailliert
              vorbereitet. Im Wallfahrtsmuseum von Gößweinstein haben sich
              originale Farbenwürfe insbesondere des Kuppelgemäldes und des
              Chorgemäldes erhalten.
            </p>

            <h4 className="referenceH4">Erhaltungszustand / Befundsituation</h4>
            <p className="referenceText">
              Die Gemälde zeichnen sich durch eine starke, durch das Heizsystem
              bedingte Oberflächenverschmutzung aus. Partiell haben sich
              Salzausblühungen gebildet.
            </p>

            <h4 className="referenceH4">Naturwissenschaftliche Analyse</h4>
            <p className="referenceText">
              Um sicherzugehen, dass es sich um eine freskal angelegte Malerei
              handelte wurde eine Probe entnommen. Die naturwissenschaftliche
              Untersuchung brachte den Nachweis einer freskal gebundenen
              Malerei, dass also der Farbauftrag auf den feuchten Putz erfolgte.
            </p>

            <h4 className="referenceH4">Restauratorische Zielstellung</h4>
            <p className="referenceText">
              Zunächst standen die Substanzsicherung, Reinigungsproben und eine
              Salzreduzierung im Vordergrund.
            </p>

            <h4 className="referenceH4">Ritzungen</h4>
            <p className="referenceText">
              Kolmsberger übertrug seine Komposition mit einer Ritzzeichung auf
              das Deckengewölbe. An den weichen Ritzkonturen kann man erkennen,
              dass diese auf dem feuchten Putz ausgeführt wurde.
            </p>

            <h4 className="referenceH4">Maßnahmen</h4>
            <p className="referenceText">
              In einigen Bereichen war eine Retusche der Fehlstellen
              erforderlich. Kolmspergers Sohn fertige die Gemälde in der
              Vierung. Bei diesen Gemälden handelte es um Seccomalereien, deren
              Farbabhebungen gefestigt werden mussten. Da der 15teilige Gemäldezyklus von
              besonderem kunstgeschichtlichen Interesse ist, wurde eine
              Kartierung der Tagewerksgrenzen von allen Gemälden angefertigt.
            </p>

            <h4 className="referenceH4">Auftraggeber</h4>
            <p className="referenceText">
              Kath. Kirchenverwaltung Gößweinstein, Erzbischöfliches Bauamt
              Bamberg.
            </p>

            <h4 className="referenceH4">Fachbehörden</h4>
            <p className="referenceText">
              Betreuung von Referatsleiter Restaurator Bernhard Symank.
            </p>
          </div>
        </div>
      </section>
      <Footer />
    </>
  )
}
